@import 'src/utils/utils';

.checkbox {

  input {

    &:not(:disabled):focus {

      & + .checkbox__box {
        box-shadow: 0px 0px 0px 3px rgba(100, 106, 137, 0.25);
      }
    }

    &:checked {

      & + .checkbox__box {
        background-color: $Corporate-2;
        border-color: $Corporate-2;
      }
    }
  }

  .checkbox {

    &__box {
      width: 24px;
      height: 24px;
      border: 1px solid $Main-5;
      border-radius: 6px;
      background-color: $Main-2;

      &:before {
        position: absolute;
        top: 2px;
        left: 7px;
        width: 8px;
        height: 13px;
        border-right: 2px solid $Main-2;
        border-bottom: 2px solid $Main-2;
        transform: rotate(45deg);
        content: '';
      }
    }
  }

  &__label {
    @include Text-14-reg;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {

        .checkbox__box {
          box-shadow: 0px 0px 0px 3px rgba(100, 106, 137, 0.25);
        }
      }
    }

    a {
      text-decoration: underline;
      color: $Corporate-2;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.3s;
  
      &:hover {
        @media(min-width: $lg) {
          text-decoration-color: $Corporate-2;
        }
      }
    }
  }

  &__box {
    position: relative;
    margin-bottom: auto;
    transition:
      background-color 0.3s,
      box-shadow 0.3s,
      border-color 0.3s;
    flex-shrink: 0;
    order: -1;
  }
}